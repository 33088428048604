import {Component} from '@angular/core';
import {AuthenticationService} from '../services/authentication.service';
import {Router} from '@angular/router';

import notify from 'devextreme/ui/notify';

import {ConfigService} from '../services/config.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  username: string;
  password: string;

  constructor(private auth: AuthenticationService, private router: Router, private config: ConfigService) {
  }

  async login() {
    const loginResult = await this.auth.authenticate(this.username, this.password);
    if (loginResult == 'OK') {
      this.config.initCurrentHotel();
      this.router.navigateByUrl('/');
    } else {
      notify('Incorrect user or password', 'error');
    }
  }
}
